<template>
  <div class="content">
    <div class="columns">
      <div class="column is-one-third">
        <label class="is-block has-text-grey-light">
          Filename
        </label>
        <a
          class="title is-6 has-text-primary"
          title="Download sheet"
          v-tippy="{ placement: 'right' }"
          @click="download({ id: item.id, filename: item.filename })"
        >
          {{ truncate(item.filename) }}
          <span class="icon is-small is-size-6 has-text-soft">
            <i class="fad fa-file-download" />
          </span>
        </a>
      </div>
      <div class="column">
        <label class="is-block has-text-grey-light">
          Uploaded
        </label>
        <div class="title is-6 has-text-grey">
          {{ item.date.value }}
        </div>
      </div>
      <div class="column">
        <label class="is-block has-text-grey-light">
          Vehicles detected
        </label>
        <div class="title is-6 has-text-grey">
          <span>
            {{ loadedData.length }}
          </span>
          <a
            class="icon has-text-info is-size-7 is-marginless"
            @click="setFilter('data')"
          >
            <i class="fad fa-filter"></i>
          </a>
        </div>
      </div>
      <div class="column">
        <label class="is-block has-text-grey-light">
          Vehicles not found
        </label>
        <div class="title is-6 has-text-grey">
          <span>
            {{ this.meta.vrmUnavailable }} ({{
              rowPercentage(this.meta.vrmUnavailable)
            }}%)
          </span>
          <a
            class="icon has-text-info is-size-7 is-marginless"
            @click="setFilter('vrmUnavailableRows')"
          >
            <i class="fad fa-filter"></i>
          </a>
        </div>
      </div>
      <div class="column">
        <label class="is-block has-text-grey-light">
          Vehicles without valuations
        </label>
        <div class="title is-6 has-text-grey">
          <span>
            {{ this.meta.valuationUnavailable }} ({{
              rowPercentage(this.meta.valuationUnavailable)
            }}%)
          </span>
          <a
            class="icon has-text-info is-size-7 is-marginless"
            @click="setFilter('valuationUnavailableRows')"
          >
            <i class="fad fa-filter"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import truncate from 'truncate'
export default {
  name: 'MultiSummarySheetInfo',
  computed: {
    ...mapGetters({
      item: 'multi/summaryItem',
      meta: 'multi/summary/meta',
      loadedData: 'multi/summary/data'
    })
  },
  methods: {
    ...mapActions({
      setFilter: 'multi/summary/setFilter',
      download: 'multi/download'
    }),
    rowPercentage(input) {
      return Math.round((input / this.item.rows.total) * 1000) / 10
    },
    truncate(value) {
      return truncate(value, 50)
    }
  }
}
</script>
